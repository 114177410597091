




















import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator } from '@/services';
import { Hanasu } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { UserCredentials } from '@/types';
import { AxiosError, AxiosResponse } from 'axios';
import { ModuleProvider, NavRouter } from '@/services';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';

@Component({
  components: {
    AsyncButton,
  }
})
export default class LoginView extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.HANASU_INSTANCE)
  private hanasu!: Hanasu;

  @lazyInject(TYPES.MODULE_PROVIDER_INSTANCE)
  private module_provider!: ModuleProvider;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  private email: string = 'taylorbr.ont@gmail.com';
  private password: string = '';
  private auth_pending: boolean = false;
  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.LOGIN;

  mounted(): void {
    this.nav_router.pushModuleLifecycleEvent({module: this.module_type, event: VUE_LIFECYCLE_EVENT.MOUNTED});
    this.auth_pending = false;
  }

  login(): void {
    const user_credentials: UserCredentials = {
        email: this.email,
        password: this.password
    };

    this.auth_pending = true;
    this.authenticator.tryLogin(user_credentials)
      // .then( (auth_response: boolean) => {
      // })
      .catch( (axios_error: AxiosError) => {
        console.log('login error');
        console.log(axios_error);
      }).finally( () => {
        //this.auth_pending = false;
      });
  }
}
